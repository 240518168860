import React from "react";
import * as FontAwesome from "react-icons/fa";
import { MdPeople } from "react-icons/md";
import { StyledButton, StyledDiv, StyledH1, StyledH3, StyledImgFluid, StyledP } from "./VehicleCardStyled";


const VehicleCard = ({ category, description, maxPeople, maxLuggage, fluid, price, amenities, onSelect }) => {

    function Greeting(props) {
        const isLoggedIn = props.isLoggedIn;
        if (isLoggedIn) {
            return <StyledButton onClick={onSelect}>{"Book Now"}</StyledButton>
        }
        return <StyledButton onClick={() => window.$crisp.push(['do', 'chat:open'])}>{"Contact Us"}</StyledButton>
    }

    const FaLuggageCart = FontAwesome["FaLuggageCart"];
    return (
        <StyledDiv className="Wrapper">
            <StyledDiv className="Header">
                <div>
                    <StyledH1>{category}</StyledH1>
                    <StyledH3>{description}</StyledH3>
                </div>
                <StyledDiv className="Restrictions">
                    <StyledDiv className="Restriction">
                        <MdPeople />
                        <span>max. {maxPeople}</span>
                    </StyledDiv>
                    <StyledDiv className="Restriction">
                        {React.createElement(FaLuggageCart)}
                        <span>max. {maxLuggage}</span>
                    </StyledDiv>
                </StyledDiv>
            </StyledDiv>
            <StyledDiv className="MainContent">
                <StyledDiv className="Advantages">
                    {amenities.map((item, index) => {
                        const faIcon = FontAwesome[item.icon];
                        return (
                            <StyledDiv key={index} className="Advantage">
                                {item.icon ? React.createElement(faIcon) : <div></div>}
                                <StyledP>{item.amenity}</StyledP>
                            </StyledDiv>
                        );
                    })}
                </StyledDiv>
                <StyledDiv className="ImageWrapper">
                    <StyledImgFluid fluid={fluid} objectFit="contain" placeholderStyle={{ visibility: "hidden" }} />
                </StyledDiv>
                <StyledDiv className="PriceWrapper">
                    <div>
                        <StyledH1 className="price">{price ? price + " EUR" : "Request A Quote" }</StyledH1>
                        <StyledP>{price ? "All prices include VAT, fees & tip" : ""}</StyledP>
                    </div>
                    <Greeting isLoggedIn={price} />
                </StyledDiv>
            </StyledDiv>
        </StyledDiv>
    );
};

export default VehicleCard;
