import styled from "styled-components";
import Img from "gatsby-image";

const StyledDiv = styled.div`
    &.Wrapper {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        background-color: #fff;
    }
    &.Header {
        display: grid;
        grid-template-columns: auto 15rem;
        border-bottom: 2px solid #ccc;
    }
    &.Restriction {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 0 0.5rem;
        svg {
            width: 1.35rem;
            height: 1.35rem;
        }

        span {
            margin-left: 0.5rem;
            font-size: 1rem;
        }
    }
    &.MainContent {
        padding-top: 2rem;
        display: grid;
        grid-template-columns: 25% 50% 25%;
    }
    &.Advantages {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &.Advantage {
        display: grid;
        grid-template-columns: 2rem auto;
        margin: 0.35rem 0;

        svg {
            width: 1rem;
            height: 1rem;
            margin: 0.25rem 0 0 0.5rem;
        }
    }
    &.ImageWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &.PriceWrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: #e9ecef;
        padding: 0.75rem;
        text-align: right;

        div {
            margin-bottom: 0.75rem;
        }
    }
    &.Restrictions {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    @media (max-width: 800px) {
        &.Header {
            grid-template-columns: 1fr;
            grid-template-rows: auto auto;
        }
        &.Restrictions {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        }
        &.Restriction {
            margin: 0.5rem 0.5rem 0.5rem 0;
        }
        &.MainContent {
            grid-template-columns: 1fr;
            grid-template-rows: auto auto auto;
            grid-gap: 1.5rem;
        }
        &.ImageWrapper {
            grid-row: 1/2;
        }
        &.Advantages {
            grid-row: 2/3;
        }
    }
`;

const StyledH1 = styled.h1`
    font-size: 1.5rem;

    &.price {
        font-size: 1.5rem;
    }
`;

const StyledH3 = styled.h3`
    font-size: 1.1rem;
    font-weight: 400;
    margin: 0.25rem 0;
`;

const StyledP = styled.p`
    margin-bottom: 0;
`;
const StyledImgFluid = styled(Img)`
    width: 80%;
    margin: auto;
    img {
        object-fit: contain;
    }
`;
const StyledButton = styled.button`
    border: none;
    background-color: var(--clr-red-light);
    width: 100%;
    padding: 0.75rem 0.5rem;
    cursor: pointer;
    color: #fff;
    font-size: 1.1rem;
    font-weight: 700;
    border-radius: var(--radius);

    &:hover {
        opacity: 0.8;
    }
`;

export { StyledDiv, StyledH1, StyledH3, StyledP, StyledImgFluid, StyledButton };
